import { type } from "jquery";

export const json = {
  completedHtml: "<h3>Большое спасибо!</h3> <h5>Благодаря вашим отзывам - мы становимся лучше. Приезжайте к нам снова!</h5>",
  pages: [{
    elements: [{
        name: "howOld",
        title: "Сколько вам лет?",
        type: "radiogroup",
        choices: [
            { value: '18-24', text: "18-24" },
            { value: '25-34', text: "25-34" },
            { value: '35-44', text: "35-44" },
            { value: '45-54', text: "45-54" },
            { value: '55 и старше', text: "55 и старше" }
        ],
        isRequired: true
    }]
},{
    "elements": [{
      "type": "checkbox",
      "name": "WorkingOrNot",
      "title": "Вы учитесь или работаете?",
      "choices": [ "Работаю", "Учусь"],
      "isRequired": true,
      "colCount": 1,
    }]
  },{
    elements: [{
        name: "howKnow",
        title: "Как вы о нас узнали?",
        type: "radiogroup",
        choices: [
            { value: 'Инстаграм', text: "Ин***грам" },
            { value: 'ВКонтакте', text: "Вконтакте" },
            { value: 'Где-то увидел рекламу', text: "Где-то увидел рекламу" },
            { value: 'Друзья', text: "Друзья" },
            { value: 'Яндекс карты', text: "Яндекс карты" },
            { value: 'Свой вариант', text: "Свой вариант"},
        ],
        isRequired: true
    }, {
        name: "Drugoe",
        title: "Свой вариант:",
        type: "comment",
        isRequired: false,
        visibleIf: "{howKnow} = 'Свой вариант'"
    }]
},{
    elements: [{
        name: "FirstTimer",
        title: "Вы у нас впервые?",
        type: "radiogroup",
        choices: [
            { value: 'Да', text: "Да" },
            { value: 'Нет', text: "Нет" }
        ],
        isRequired: true
    }]
},{
    elements: [{
        name: "domik",
        title: "Какой из наших домиков вы выбрали?",
        type: "radiogroup",
        choices: [
            { value: 'Лето', text: "Лето" },
            { value: 'Облака', text: "Облака" },
            { value: 'Море', text: "Море" },
            { value: 'Лес', text: "Лес" },
            { value: 'Чили', text: "Чили" },
            { value: 'Поле', text: "Поле" }
        ],
        isRequired: true
    }]
},{
    "elements": [{
      "type": "checkbox",
      "name": "WhyThisDomik",
      "title": "Почему вы выбрали этот домик?",
      "choices": [ "Понравился интерьер", "Лучший вид из окна", "Уединенное расположение", "Друзья посоветовали", "Это был единственный свободный домик на нужную дату"],
      "isRequired": false,
      "colCount": 1,
    }]
  },
{
    elements: [{
        name: "Positive",
        title: "Что вам понравилось в домике, на территории и тд?",
        type: "comment",
        isRequired: true
    }],
}, {
    elements: [{
        name: "Negative",
        title: "Что вам НЕ понравилось или что мы могли бы добавить в домик, на территорию и тд?",
        type: "comment",
        isRequired: true
    }],
}, {
    elements: [{
        name: "Recommend",
        title: "Вы посоветуете Panorama Village своим друзьям?",
        type: "radiogroup",
        choices: [
            { value: 'Да', text: "Да" },
            { value: 'Нет', text: "Нет" }
        ],
        isRequired: true
    },{
        name: "nemail",
        title: "Можете оставить ваш email для обратной связи:",
        type: "comment",
        isRequired: false
    }]
}]
};