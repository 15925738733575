// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./res/try-clother-light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./res/forest.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Clother"; 
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype"); 
  font-style: normal; 
  font-weight: lighter; 
  }
  
* {
  font-family: "Clother"!important;
  text-decoration: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
}

.app-content {
  overflow-y: hidden;
}

h1 {
  font-size: 20px;
}

h1,
h1+span {
  padding: 0 20px;
}

.jumbotron ul {
  font-size: 21px;
}

body {
  overflow: -moz-scrollbars-horizontal;
  overflow-y: hidden;
  overflow-x: auto;
  position:fixed;
  width: 100vw;
  background: var(--tg-theme-bg-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: repeat;
  background-size: 35%;
  color: var(--tg-theme-text-color);
  height: var(--tg-viewport-height);
}
#root > div > div > div > div {
  background-color:transparent;
}
.navbar-header {
  background-color: #ededdd;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,+DAA0D;EAC1D,kBAAkB;EAClB,oBAAoB;EACpB;;AAEF;EACE,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,oCAAoC;EACpC,yDAAyC;EACzC,yBAAyB;EACzB,oBAAoB;EACpB,iCAAiC;EACjC,iCAAiC;AACnC;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["@font-face {\n  font-family: \"Clother\"; \n  src: url(\"./res/try-clother-light.ttf\") format(\"truetype\"); \n  font-style: normal; \n  font-weight: lighter; \n  }\n  \n* {\n  font-family: \"Clother\"!important;\n  text-decoration: none;\n}\n\n.App-logo {\n  animation: App-logo-spin infinite 20s linear;\n}\n\n.app-content {\n  overflow-y: hidden;\n}\n\nh1 {\n  font-size: 20px;\n}\n\nh1,\nh1+span {\n  padding: 0 20px;\n}\n\n.jumbotron ul {\n  font-size: 21px;\n}\n\nbody {\n  overflow: -moz-scrollbars-horizontal;\n  overflow-y: hidden;\n  overflow-x: auto;\n  position:fixed;\n  width: 100vw;\n  background: var(--tg-theme-bg-color);\n  background-image: url(\"./res/forest.png\");\n  background-repeat: repeat;\n  background-size: 35%;\n  color: var(--tg-theme-text-color);\n  height: var(--tg-viewport-height);\n}\n#root > div > div > div > div {\n  background-color:transparent;\n}\n.navbar-header {\n  background-color: #ededdd;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
